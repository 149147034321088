import React from "react"
//import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

/* Images from old character planner site. likely need to be converted to PNGs now that those are more standard. */
import CheckoffImage from "../images/CheckoffImage.gif"
import ThreePictures from "../images/ThreePictures.gif"
import ClipartRow from "../images/ClipartRow.gif"
import Deut667 from "../images/Deut667.gif"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <p align="center">Character Planner is a software package that is designed to help families create school planners, chore check-off sheets and other useful organization and character building tools. This program was developed by a home school family for home school families. It is based on the lessons learned and experience gained from years of home schooling using planners similar to what Character Planner will create. Our goal is to help you in your endeavor to build the Character of Christ in your children's lives.</p>
		<div class="CP_BackgroundMemories">
			<p><img class="CP_FloatImageRight" src={CheckoffImage} alt="" height="162" width="93" border="0" />
      <strong>Character Planner Check-Off System</strong></p>
			<p>The planners that Character Planner creates use a check-off system to help organize various routines of your family's day to day life. Character Planner software creates a fun, colorful and systematic way for children to complete their school, household and free time responsibilities. Most importantly, these planners enable Dad to be involved with his family's daily activities by providing a way for him to review the children's responsibilities. This provides valuable accountability for children.</p>
			<p><strong>A Place for Memories - Journal and Records</strong></p>
			<p>A planner created with Character Planner is a great place to store family memories and write about special activities. The planner not only helps instill character as children learn responsibility with school and household chores, but also keeps a record of school work and daily activities.</p>
		</div>
		<p><strong>Templates - Pick One and Start</strong></p>
		<p>Character Planner includes many useful templates. These templates are available as a starting point for your planner pages. Included are several styles of check-off sheets, meal planning sheets, field trip lists as well as many others. The templates are easy to use with a simple point-and-click interface. Just click children's names, activities and clip art to customize. Your custom planner is then ready to print and use.</p>
		<p><img class="CP_FloatImageRight" src={ThreePictures} alt="" height="90" width="100" border="0" /><strong>Clipart and Photos - Create Your Own Custom Pages</strong></p>
		<p>Character Planner has a library of clipart specifically drawn for use in your school or chore planner. This clipart art can be easily added to your planner making it easy to understand for small children. If you don't find what you need in Character Planner's clipart library, you can import images from a variety of other sources. This could be clipart that you already have as well as digital pictures.</p>
		<p align="center"><img src={ClipartRow} alt="" height="45" width="600" border="0" /></p>
		<p align="center"><img src={Deut667} alt="" height="99" width="514" border="0" /></p>
  </Layout>
)

export default IndexPage
